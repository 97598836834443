import React from 'react';
import { Link } from 'gatsby';
import styles from '../../content/css/error.module.css';

const error = () => {
  return (
    <section className={styles.error}>
      <div className={styles.message}>
        <h1>Nothing to see here.</h1>

        <p>
          <Link to='/'>
            <p>Go back to home page of Blue Book Security</p>
          </Link>
        </p>
      </div>
    </section>
  );
};

export default error;
